import React, { useState } from "react";
import ReactPlayer from "react-player";
import InterviewTips from "../interview-tips/interviewTips";
import TutorialNavBar from "../tutorial-nav-bar/TutorialNavBar";
import ConverLetter from "../cover-letter/ConverLetter";
import PhoneCall from "../phone-call/PhoneCall";
import HRQuestions from "../HRQuestions/HRQuestions";
import Letter from "../Letter/Letter";
import SalaryNegotiation from "../SalaryNegotiation/SalaryNegotiation";

const TutorialQuestion = () => {
  const [showNav, setShowNav] = useState(1);
  const handleChildCountChange = (navCount: number) => {
    setShowNav(navCount);
  };

  return (
    <div className=" lg:container lg:mx-auto lg:py-16 md:py-12 md:px-6 py-12 px-4">
      <TutorialNavBar handleChildCountChange={handleChildCountChange} />
      {showNav === 1 && <InterviewTips />}
      {showNav === 2 && <ConverLetter/>}
      {showNav === 3 && <PhoneCall/>}
      {showNav === 4 && <HRQuestions/>}
      {showNav === 5 && <Letter/>}
      {showNav === 6 && <SalaryNegotiation/>}

    </div>
  );
};

export default TutorialQuestion;
